import Dashboard from "../components/molecule/Dashboard/Dashboard";
import Header from "../components/molecule/Header/Header";

const DashboardPage = () => {
    return (
        <div id="container">
            <Header /> 
            <main>
                <section>
                    <div className="cart-page">
                        <Dashboard />
                    </div>
                </section>
            </main>
        </div>
    )
}

export default DashboardPage;