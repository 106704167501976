import { useRouteError } from "react-router-dom";
import Header from "../components/molecule/Header/Header";
import OopsMonkey from "../assets/images/oopsMonkey.png"
import { useNavigate } from "react-router-dom";

import './ErrorPage.scss';

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();
  console.error(error);

  const handleReturntoHome = () => {
    navigate('/shop')
  }

  return (
    <div id="container">
        <Header showBasket={false}/>
        <div className="monkeyOuter">
            <img className="monkey" src={OopsMonkey} alt="Oops Monkey" />
        </div>
        <div className="oops">Oops!</div>
        <p style={{fontSize: '24px'}}>Sorry, an unexpected error has occurred.</p>
        <p>
            <i>{error.statusText || error.message}</i>
        </p>
        <div className="returnHome">
            <button className="continueButton" onClick={handleReturntoHome}>Return to home</button>
        </div>
    </div>
  );
}