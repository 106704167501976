import './Create.css';
import '../components/organism/2DGenerator/2DGenrator.scss';
import axios from "axios";
import { Core3D, Material, Model } from '@core3d/sdk';
import { Adapter } from '@core3d/sdk-adapter-three'
import { useContext, useEffect, useRef, useState } from 'react';
import SizePanel from '../components/molecule/SizePanel/SizePanel';
import ImageThumbPanel from '../components/molecule/ImageThumbPanel/ImageThumbPanel';
import BuyButton from '../components/atom/BuyButton/BuyButton';
import { selectContext, operatingContext } from '../context/Context';
import ShoppingBasket from '../components/molecule/ShoppingBasket/ShoppingBasket';
import PlacementPanel from '../components/molecule/PlacementPanel/PlacementPanel';
import Loading from '../components/atom/Loading/Loading'
import Drawer from '../components/atom/Drawer/Drawer';
import Header from '../components/molecule/Header/Header';
import { useShoppingBasket } from '../context/Context';
import { useNavigate } from 'react-router-dom';
import AddImage from '../components/molecule/AddImage/AddImage';
import ConfirmBox from '../components/atom/ConfirmBox/ConfirmBox';
import { getApp, initializeApp } from "firebase/app";

import AddText from '../components/molecule/AddText/AddText';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import { SvgIcon } from '@mui/material';

// 2D Genrator imports
import { Canvas, Rect, FabricImage, FabricText } from 'fabric'
import * as fabric from 'fabric';
import ColourPicker from '../components/atom/ColourSelect/ColourSelect';
import { randomNumber } from '../helpers/numberHelpers';

import { loadImage } from '../helpers/ImageHelpers';

import {
  ChakraProvider,
  theme,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  renderPanel
} from '@chakra-ui/react';
import OrientationPanel from '../components/molecule/OrientationPanel/OrientationPanel';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { isValidFileExtension, getImageDPI, getPngDPI, getImageDetails, getImageDetailsFromURL, imageUrlToFile } from '../helpers/ImageHelpers';

import testImage from '../assets/images/MerchTSRoundFront.png'
import UploadedImage from '../assets/images/Dystopia.jpeg'
import FontSelect from '../components/atom/FontSelect/FontSelect';
import { readLocalStorage, writeLocalStorage } from '../helpers/cookies';

const Create = () => {
  const {selectedColour, selectedPlace, selectedSize, selectedMerch, setSelectedMerch} = useContext(selectContext);
  const { state } = useShoppingBasket();
  const [showSizes, setShowSizes] = useState(false);
  const [isRendering, setIsRendering] = useState(false);

  const {confirmOpen, setConfirmOpen} = useContext(selectContext);
  const {uploadedImage, setUploadedImage} = useContext(selectContext)
  const [preview, setPreview] = useState()
  const [isUploading, setIsUploading] = useState(false);
  const [imageInfo, setImageInfo] = useState(null)
  const [imageSize, setImageSize] = useState({width: null, height: null });
  const {imageAlbum, setImageAlbum } = useContext(operatingContext);
  const {openUpload, setOpenUpload} = useContext(operatingContext);
  const {openURLUpload, setOpenURLUpload} = useContext(operatingContext);
  const {openDDUpload, setOpenDDUpload} = useContext(operatingContext);
  const {setOpenAddConfirm} = useContext(operatingContext);
  const [imageAssess, setImageAssess] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(false);
  const {uploadedImageURI, setUploadedImageURI} = useContext(selectContext);
  const {selectedImage, setSelectedImage } = useContext(selectContext);
  const {currentSelection, setCurrentSelection} = useContext(selectContext);
  const [imageUrl, setImageUrl] = useState('');
  const {selectedOri} = useContext(selectContext);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [error, setError] = useState('');
  const [ddFiles, setDDFiles] = useState(null);

  const [showMask, setShowMask] = useState(false);

  const [canvasColour, setCanvasColour] = useState('#ffffff')
  const [colourValue, setColourValue] = useState('#FF0099');
  const [textValue, setTextValue] = useState()
  const [basketImage, setBasketImage] = useState()

  const { dispatch } = useShoppingBasket();
  let navigate = useNavigate();

  const [localMerch, setLocalMerch] = useState(() => {
          if(!selectedMerch){
            const selectMerch = readLocalStorage('selectedMerch');
            return selectMerch
          } else {
            return selectedMerch
          }
  })

  const [localAlbum, setLocalAlbum] = useState(() => {
      if(imageAlbum.length===0){
        const imgAlbum = readLocalStorage('imageArray');
        return imgAlbum;
      } else {
        return imageAlbum;
      }
  })
  const [dims, setDims] = useState(null)


  const firebaseConfig = {
    apiKey: 'AIzaSyAo70-9MM2aZCx5bVGrytpdLkdARg6TofU',
    authDomain: 'yaayinitial.firebaseapp.com',
    projectId: 'yaayinitial',
    storageBucket: 'yaayinitial.appspot.com',
    messagingSenderId: '468508283422',
    appId: '1:468508283422:web:4e0b1b195852c31185d40f',
    measurementId: 'G-2JTGLCZNNE'
  };


  //id, itemName, sku, itemCost, size, colour, 1
  const handleAdd = (id, itemName, sku, itemCost, itemSize, itemColour, itemCount, itemImage) => {
    console.log('handleAdd', id, itemName, sku, itemCost, itemSize, itemColour, itemCount, itemImage)
    dispatch({
      type: 'ADD_ITEM',
      payload: { id: id, name: itemName, sku: sku, itemCost: itemCost, itemColour, itemSize: itemSize, itemCount: itemCount, itemImage: itemImage }
    });
  };

  const handleEdit = (id, newCount) => {
    dispatch({ 
      type: 'EDIT_ITEM', 
      payload: { id: id, updates: { itemCount: newCount } } });
  };

  const handleConfirm = result => {
    if (result) {

      if(result) {
        const findImageIndex = (element) => element.id === confirmOpen.imgId;
        localAlbum.splice(localAlbum.findIndex(findImageIndex), 1)
        setImageAlbum(localAlbum)
        writeLocalStorage('imageArray', imageAlbum)
      }
    }
    
    setConfirmOpen({state: false, imgId: null});
  }

  //TODO: 2D Generator stuff
    let select_item = '';
    let select_price = '+0';
    let price = 299;

    const canvasRef = useRef(null);
    const fabricRef = useRef(null);
    const [canvas, setCanvas] = useState(null);
    const [showTextEdit, setShowTextEdit] = useState(null);
    const [showImageEdit, setShowImageEdit] = useState(null)

    const item_list = [];

        const handleObjectSelect = (selected) => {
          if(selected.type === 'text'){
            setTextValue(selected.text);
            setShowTextEdit({text: selected.text, font: selected.fontFamily, colour: selected.fill})
          } else if(selected.type === 'image'){
            setShowImageEdit({img: selected})
          }
        }

        const handleObjectUnselect = () => {
          setShowTextEdit(null);
          setShowImageEdit(null);
        }

        const handleImageDelete = () => {
          if(canvas){
            const imageToDelete = canvas.getActiveObject();
            console.log('delete', imageToDelete);
            canvas.remove(imageToDelete)
            // TexttoEdit.set({text: textValue, fontFamily: fontValue, fill: colourValue})
            // canvas.renderAll();
          }
        }

        const showPreview = () => {
          console.log('showing preview');
          const ctx = canvas.getContext("2d");
          console.log(ctx)
          ctx.beginPath();
          ctx.rect(dims.rectboxX, dims.rectboxY, dims.rectboxWidth, dims.rectboxHeight);
          ctx.closePath();
          ctx.lineWidth = 2;
          ctx.strokeStyle = 'rgba(0, 0, 0, 0)';
          ctx.stroke();
          ctx.clip();
        }

        const handleCenterH = () => {
          if(canvas){
            const imgToEdit = canvas.getActiveObject();
            const imgY = imgToEdit.top;
            const offSet = (imgToEdit.width*imgToEdit.scaleX)/2;
            const half = dims.rectboxWidth/2;
            const xCoord = (dims.rectboxX + half)-offSet;
            imgToEdit.set({left: xCoord})
            imgToEdit.setCoords({left:xCoord, top: imgY})
            canvas.renderAll();
          }
        }

        const handleCenterV = () => {
          if(canvas){
            const imgToEdit = canvas.getActiveObject();
            const imgX = imgToEdit.left;
            const offSet = (imgToEdit.height*imgToEdit.scaleY)/2;
            const half = dims.rectboxHeight/2;
            const yCoord = (dims.rectboxY + half)-offSet;
            imgToEdit.set({top: yCoord})
            imgToEdit.setCoords({left:imgX, top: yCoord})
            canvas.renderAll();
          }
        }

        const handleTextEdit = () => {
          if(canvas){
            const TexttoEdit = canvas.getActiveObject();
            TexttoEdit.set({text: showTextEdit.text, fontFamily: showTextEdit.font, fill: colourValue})
            canvas.renderAll();
          }
        }

        const handleAddImage = (newImage) => {
          console.log('I am creating ')
          const imgObj = new Image(200,200)
          imgObj.crossOrigin = "Anonymous";
          imgObj.onerror = function() { console.log("cross-origin image load error"); }
          imgObj.src = newImage.src;
          imgObj.onload = function () {
            const newImg = new FabricImage(imgObj, {
              cornerStrokeColor: "#ff0098",
              borderColor:'#ff0098'
            })
            
            newImg.set({
              left: dims.rectboxX,
              top: dims.rectboxY
            });
            console.log('newIng', newImg)
            newImg.scaleToWidth(dims.rectboxWidth+1);
            canvas.add(newImg);
          }
          
      }

        const handleAddText = (textValue, fontValue, colourValue) => {
          console.log(textValue, fontValue, colourValue)
          const newText = new FabricText(textValue, {
              fontSize: 18,
              fontFamily: fontValue,
              left: dims.rectboxX,
              top: dims.rectboxY,
              fill: colourValue,
              selectable: true,
              hasControls: true,
              hasBorders: true,
              cornerStrokeColor: "#ff0098",
              borderColor:'#ff0098'
              });
          newText.scaleToWidth(dims.rectboxWidth);
          canvas.add(newText);
          canvas.setActiveObject(newText);
      }

      const createBasketImage = () =>{
        const basketImage = canvas.toDataURL({
          left: 0,
          top: 0,
          height: 500,
          width: 600,
        })
        // const basketImgObj = new Image(500, 400)
        // basketImgObj.crossOrigin = "Anonymous";
        // basketImgObj.onerror = function() { console.log("cross-origin image load error"); }
        // basketImgObj.src = basketImage;

        console.log('basketImage', basketImage);
        setBasketImage(basketImage);
      }
  
      // Start Canvas
      useEffect(() => {
          setOpenDDUpload(false);
          setOpenURLUpload(false);
          setOpenUpload(false);
          // console.log('local', readLocalStorage('selectedMerch'))
          // if(!selectedMerch){
          //   const selectMerch = readLocalStorage('selectedMerch');
          //   setSelectedMerch(selectMerch)
          // }
          // if(!localAlbum){
          //   const localAlbum = readLocalStorage('imageArray');
          //   setImageAlbum(localAlbum)
          // }
          if (canvasRef.current){
              const initCanvas = new Canvas(canvasRef.current, {
                  height: 500,
                  width: 600,
                  allowTaint: true
              });
  
              initCanvas.renderAll();
              setCanvas(initCanvas);
  
              return () => {
                  initCanvas.dispose();
              }
          }}, [])

      useEffect(() => {
        if(showTextEdit){
          const editText = showTextEdit;
          editText.text = textValue;
          setShowTextEdit(editText);
        }
        
      }, [textValue])

      useEffect(() => {
        if(showMask){
          //deselect all objects
          const selectedItem = canvas.getActiveObject();
          if (selectedItem){
            console.log('I have a to remove selection')
            console.log('selected', selectedItem)
          }
          
          const printImage = canvas.toDataURL({
            left: dims.rectboxX + 1,
            top: dims.rectboxY + 1,
            height: dims.rectboxHeight-1,
            width: dims.rectboxWidth-1,
          })
          console.log(printImage)

          const printImgObj = new Image(500, 400)
          printImgObj.crossOrigin = "Anonymous";
          printImgObj.onerror = function() { console.log("cross-origin image load error"); }
          printImgObj.src = printImage;
          //printImgObj.src = newImageObj.images[0].name;
          const prevImgPrint = new FabricImage(printImgObj, {
            id: 'printImg',
            left: dims.rectboxX + 1,
            top: dims.rectboxY + 1,
            height: dims.rectboxHeight,
            width: dims.rectboxWidth,
            selectable: false,
            hasControls: false,
            hasBorders: false,
            cornerStrokeColor: "#ff0098"
            });

          const merchImages = localMerch.images;
          const newImageObj = merchImages.find(item => item.colour === selectedColour);
          const prevImgObj = new Image(500, 400)
          prevImgObj.crossOrigin = "Anonymous";
          prevImgObj.onerror = function() { console.log("cross-origin image load error"); }
          prevImgObj.src = newImageObj.images[0].name;
          loadImage(newImageObj.images[0].name).then(houseImage => {
                console.log(houseImage)
                canvas.renderAll()
                createBasketImage();
              });
          const prevImgBack = new FabricImage(prevImgObj, {
              id: 'previewImg',
              left: 0,
              top: 0,
              height: 500,
              width: 600,
              selectable: false,
              hasControls: false,
              hasBorders: false,
              cornerStrokeColor: "#ff0098"
              });
              
          const path2 = 'm -211 -60 h 180 v 225 h -180 z' 

          const clipPath2 = new fabric.Path(path2, {
            fill: '#ffffbb'
          })
          
          
          prevImgBack.set({
            left: 0,
            top: 0,
          });

          
          canvas.add(prevImgBack);
          canvas.add(prevImgPrint);

          

       } else {
          const canvObjects = canvas?.getObjects();
          console.log('objects', canvObjects)
          
          if(canvObjects){
            const currentPrevImage = canvObjects.find((item) => {
              return item.id === 'previewImg'
            })
            console.log('image', currentPrevImage)
            canvas.setActiveObject(currentPrevImage)
            canvas.remove(currentPrevImage);
          }
          if(canvObjects){
            const currentPrevImage = canvObjects.find((item) => {
              return item.id === 'printImg'
            })
            console.log('image', currentPrevImage)
            canvas.setActiveObject(currentPrevImage)
            canvas.remove(currentPrevImage);
          }
            
       }
          // const canvObjects = canvas?.getObjects();
          // console.log('objects', canvObjects)
          // if(canvObjects){
          //   const currentBackImage = canvObjects.find((item) => {
          //     return item.id === 'backImage'
          //   })
          //   console.log('image', currentBackImage)
          //   canvas.setActiveObject(currentBackImage)
          //   const activeObject = canvas.getActiveObject();
          //   activeObject.clone((cloned) => {
          //     console.log(cloned)
              // cloned.set({
              //   left: activeObject.left + 20, // Offset position to avoid overlap
              //   top: activeObject.top + 20,
              //   selectable: true,
              // });
              // canvas.add(cloned);
              // canvas.bringToFront(cloned);
              // canvas.setActiveObject(cloned); // Set the new duplicate as the active object
              // canvas.renderAll();
          //   });
          // }
          // if (!showMask) {
          //   canvas?.remove(mask);
          // } else {
          //   canvas?.add(mask);
          //   //canvas?.bringToFront(mask);
            
          // }
          canvas?.renderAll();
        
      }, [showMask])

      useEffect(() => {
        console.log('new Iamge Selected', selectedImage)
        if(selectedImage){
          const newImage = localAlbum.find(item => item.id === selectedImage);
          console.log('newImage', newImage)
          handleAddImage(newImage);
        }
        
      }, [selectedImage])

      useEffect(() => {
          if (canvas){
            console.log('localMerch', localMerch, typeof(localMerch))
            const rectboxX = localMerch?.rectDims?.rectboxX,
            rectboxY = localMerch?.rectDims?.rectboxY,
            rectboxWidth = localMerch?.rectDims?.rectboxWidth,
            rectboxHeight = localMerch?.rectDims?.rectboxHeight;
            console.log('dims', rectboxX, rectboxY, rectboxWidth, rectboxHeight)
            setDims({'rectboxX': rectboxX, 'rectboxY': rectboxY, 'rectboxWidth': rectboxWidth, 'rectboxHeight':rectboxHeight})
            const testImgObj = new Image(500, 400)
            //testImgObj.crossOrigin = "Anonymous";
            //testImgObj.onerror = function() { console.log("cross-origin image load error"); }
            if(localMerch && localMerch.images){
              testImgObj.src = localMerch?.images[0].images[0]?.name;
              const backImg = new FabricImage(testImgObj, {
                  id: 'backImage',
                  left: 0,
                  top: 0,
                  height: 500,
                  width: 600,
                  selectable: false,
                  hasControls: false,
                  hasBorders: false,
                  cornerStrokeColor: "#ff0098"
                  });
                //backImg.crossOrigin="anonymous"
                canvas.add(backImg);
                canvas.setActiveObject(backImg);
            }
              const rectbox = new Rect({
              width: rectboxWidth+2,
              height: rectboxHeight+2,
              left: rectboxX-1,
              top: rectboxY-1,
              stroke: 'rgba(66,148,247,0.5)',
              strokeWidth: 2,
              fill: 'rgba(0,0,0,0)',
              selectable: false,
              hasControls: false,
              hasBorders: false,
              excludeFromExport: true
              });
      
              canvas.add(rectbox);
              canvas.setActiveObject(rectbox);
              
              const recttext = new FabricText('Printable Area', {
              fontSize: 14,
              fontFamily: 'sans-serif',
              left: rectboxX + 48,
              top: rectboxY + rectboxHeight + 7,
              fill: 'rgba(66,148,247,0.5)',
              selectable: false,
              hasControls: false,
              hasBorders: false,
              excludeFromExport: true
              });
              
              canvas.add(recttext);
              canvas.setActiveObject(recttext);
                  
              // Create Clip Area (Object created after this will be clipped)
              // var ctx = canvas.getContext("2d");
              //  ctx.beginPath();
              //  ctx.rect(rectboxX, rectboxY,rectboxWidth, rectboxHeight);
              //  ctx.closePath();
              //  ctx.lineWidth = 2;
              //  ctx.strokeStyle = 'rgba(0, 0, 0, 0)';
              //  ctx.stroke();
              //  ctx.clip();
                  // END Clip Area

              canvas.on("selection:created", (event) => {
                handleObjectSelect(event.selected[0])
              })

              canvas.on("selection:cleared", () => {
                handleObjectUnselect()
              })
          }
      }, [canvas])
  

  //TODO: End of 2D Generator stuff

  // TODO: Move the following code to helper file

  const handleUploadClose = () => {
    setOpenUpload(false);
    setOpenURLUpload(false);
    setOpenDDUpload(false);
    setUploadedImage(null);
  }

  const handleUploadToStorage = () => {
    setIsUploading(true);
    setUploadComplete(false)
    const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
    const fd = new FormData();
    fd.append('image', uploadedImage)
    axios
      .post(baseURL, fd)
      .then((response) => {
        initializeApp(firebaseConfig)
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);
        getDownloadURL(ref(storage, uploadedImage.name))
        .then((url) => {
            const imageArray = localAlbum;
            const imageId = imageArray.length + 1;
            imageArray.unshift({id: imageId, src: url, uri: 'uri', imgWidth: imageSize.width, imgHeight: imageSize.height})
            setImageAlbum(imageArray);
            handleUpload(imageId);
        })
      }).catch(error => {
        console.log('ERROR', error)
      });
  }

  const handleUpload = (imageId) => {
    setIsUploading(true);
    const baseURL = "https://api.core3d.io/v1/uploads";
    const fd = new FormData();
    fd.append('file', uploadedImage)
    axios
      .post(baseURL, fd, { headers: { Authorization: `Bearer c3dak_NQCudjy8_8uZnX4oa6H9vEMWnirg29A2s` } })
      .then((response) => {
        setIsUploading(false);
        const imageURI = response.data.uri;
        const index = localAlbum.findIndex(item => item.id === imageId);
        const current = localAlbum;
        current[index].uri = imageURI;
        setImageAlbum(current)
        setUploadedImageURI(imageURI)
        setUploadComplete(true)
        setSelectedImage(imageId)
        setPreview(null)
        setImageInfo(null)
        setImageSize({width: null, height: null });
      }).catch(error => {
        console.log('ERROR', error)
        setUploadComplete(false);
      });
  }

  const handleDragDrop = async (files) => {
    const file = files[0];
    if (file) {
      setOpenDDUpload(true)
      const ext = file.name.split('.').pop();
      if (isValidFileExtension(ext)) {
        getImageDetails(file, (info) => {
          setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
          //setUploadError('');
        });
        if (ext === 'png'){
            getPngDPI(file, (err, dpi) => {
                if (err) {
                  setUploadError(err.message);
                  setUploadedImage(null);
                  setImageInfo(null);
                } else {
                  setImageInfo(dpi.x);
                  setUploadError('');
                  setUploadedImage(file);
                }
              });
        } else {
            getImageDPI(file, (dpi) => {  
                setImageInfo((dpi.x.numerator/dpi.x.denominator));
                setUploadError('');
              });
            setUploadedImage(file)
        }
        const objectUrl = URL.createObjectURL(file)
        if(imageInfo>140){
          setImageAssess(true)
        } else {
          setImageAssess(false)
        }
        setUploadError(null);
        setDDFiles([{file: file, imageURL: objectUrl, imageSize: 'imageDetails', imageInfo: imageInfo, imageAssess: imageAssess}]);

      } else {
        setUploadedImage(null);
        setUploadError('Invalid file type. Only images and PDFs are allowed.');
      }
    }
    
    // ********************************
    // Original multi file handling
    // const dragFiles = [...files];
    // setOpenDDUpload(true)
    // const ddFileArray = []

    // dragFiles.forEach(file => {
    //   let imageDetails, imageInfo, imageAssess
    //   if (file) {
        
    //     const ext = file.name.split('.').pop();
    //     if (isValidFileExtension(ext)) {
          
    //       imageDetails = getImageDetails(file, (info) => {
    //         //setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
    //         imageDetails = {width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight}
    //       })
    //       if (ext === 'png'){
    //           getPngDPI(file, (err, dpi) => {
    //               if (err) {
    //                 setUploadError(err.message);
    //                 // setUploadedImage(null);
    //                 // setImageInfo(null);
    //               } else {
    //                 setUploadError('');
    //                 //setImageInfo(dpi.x);
    //                 imageInfo = dpi.x
    //                 //setUploadedImage(file);
    //               }
    //             });
    //       } else {
    //           getImageDPI(file, (dpi) => {  
    //               //setImageInfo((dpi.x.numerator/dpi.x.denominator));
    //               imageInfo = (dpi.x.numerator/dpi.x.denominator)
    //               setUploadError('');
    //             });
    //           //setUploadedImage(file)
    //       }
    //       const objectUrl = URL.createObjectURL(file)
    //       if(imageInfo>140){
    //         imageAssess = true
    //       } else {
    //         imageAssess = false
    //       }
    //       setUploadError(null);
    //       ddFileArray.push({file: file, imageURL: objectUrl, imageSize: imageDetails, imageInfo: imageInfo, imageAssess: imageAssess})
    //     } else {
    //       setUploadedImage(null);
    //       setUploadError('Invalid file type. Only images and PDFs are allowed.');
    //     }
    //   }
    // })
  }

  const handleDDUploadToStorage = async(uploadFile) => {
    setIsUploading(true);
    setUploadComplete(false)
    const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
    const fd = new FormData();
    fd.append('image', uploadFile)
    axios
      .post(baseURL, fd)
      .then((response) => {
        initializeApp(firebaseConfig)
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);
        getDownloadURL(ref(storage, uploadFile.name))
        .then((url) => {
            const imageArray = localAlbum;
            const imageId = imageArray.length + 1;
            imageArray.unshift({id: imageId, src: url, uri: 'uri', imgWidth: imageSize.width, imgHeight: imageSize.height})
            setImageAlbum(imageArray);
            handleUpload(imageId);
        })
      }).catch(error => {
        console.log('ERROR', error)
      });
  }

  const handleURLUpload = async () => {
    const ext = imageUrl.split('.').pop();
    const imgFile = await imageUrlToFile(imageUrl);
    if (isValidFileExtension(ext)) {
      try {
        const response = await fetch(imageUrl);
        
        if (!response.ok) {
          throw new Error('Failed to fetch image.');
        } else {
          const img = new Image();
          img.src = imageUrl;
  
          img.onload = () => {
            setUploadedImage(imgFile);
            getImageDetailsFromURL(imageUrl, (err, info) => {
              if (err) {
                setError(err.message);
                // setImageDetails(null);
              } else {
                setError('');
                //setImageDetails(details);
                setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
                setImageInfo((info.dpi.x));
              }
            });
          }
        
          // img.onload = () => {
          //   
          //   // getImageDetails(file, (info) => {
          //   //   setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
          //   //   //setUploadError('');
          //   // });
          // }
        }
  
        initializeApp(firebaseConfig)
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);
  
        const blob = await response.blob();
        const fileName = imageUrl.split('/').pop();
        //const storageRef = ref(storage, `images/${fileName}`);
        const storageRef = ref(storage, fileName);
        const uploadTask = uploadBytesResumable(storageRef, blob);
  
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.log('ERROR', error.message)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              const imageArray = localAlbum;
              const imageId = imageArray.length + 1;
              imageArray.unshift({id: imageId, src: url, uri: 'uri', imgWidth: imageSize.width, imgHeight: imageSize.height})
              setImageAlbum(imageArray);
              setImageInfo(null)
              setImageSize({width: null, height: null })
              //handleUpload(imageId);
            });
          }
        );
      } catch (err) {
        console.log('ERROR', err)
      }
    } else {
      setUploadedImage(null);
      setUploadError('Invalid file type. Only images and PDFs are allowed.');
    }
  };


  // TODO: Move code above 

  const core3d = new Core3D({
    apiKey: 'c3dak_NQCudjy8_8uZnX4oa6H9vEMWnirg29A2s',
    createAdapter: ctx => new Adapter(ctx),
  });

  const container = useRef(null)

  const setRGBColour = () => {
    let returnColour;
    switch(selectedColour) {
      case '#000000':
        returnColour = [0,0,0,1];
        break;
      case '#ff2d2d':
        returnColour = [1,0.176,0.176,1];
        break;
      case '#2d32ff':
        returnColour = [0.176,0.196,1,1];
        break;
      case '#73c881':
        returnColour = [0.451,0.784,0.506,1];
        break;
      case '#deeb06':
        returnColour = [0.871,0.922,0.024,1];
        break;
      default:
        returnColour = [1,1,1,1];
    }

    return returnColour;
  }

  const setPlacement = () => {
    if(selectedPlace === 'frontLeftPocket'){
      return 1;
    } else {
      return 0;
    }
  }

  const buildRender = async () => {
    setIsRendering(true);
    const design = core3d.createDesign();
    const model = await core3d.loadModel(Model.Tee);
    const cotton = await core3d.loadMaterial(Material.Cotton);
    const graphic = await core3d.loadGraphic(uploadedImageURI)

    design.setModel(model);

    for (const mesh of design.listMeshes()) {
        design.apply(cotton, mesh);
    }

    cotton.setOption('color', setRGBColour());
    const zone = design.listZones().find(zone => /front/i.test(zone.getName()));
    if ( zone ) {
      const assignment = design.apply(graphic, zone);
      const placement = zone.placements[setPlacement()];
      if (placement) {
        assignment.setPlacement(placement.name)
      }
    }

    await design.render();

    const scene = await core3d.loadScene({
      layout: 'front',
      size: [512, 512],
      target: design,
    });

    scene.fit();
    scene.start();

    container.current.innerHTML = "";
    container.current.append(scene.element);
    setIsRendering(false);
  }

  const handleFileSelect = (e) =>{
    setUploadedImage(e.target.files[0])
  }

  const handleBasketAdd = () => {
    console.log(localMerch, selectedColour, selectedSize)
    const itemName = localMerch.name;
    const sku = localMerch.sku
    const size = selectedSize;
    const colour = selectedColour;
    const itemCost = 39.99
    const id = `${sku}-${randomNumber()}-${size}-${colour}`
    const result = state.items.filter(obj => { return obj.id === id})
    console.log('result', result);
    setCurrentSelection(
      {itemName: itemName, 
        sku: sku, 
        size: selectedSize, 
        colour: selectedColour,
        availColours: localMerch.colours,
        availImages: localMerch.images,
        itemCost: localMerch.ItemCost,
        sizes: localMerch.sizes,
        showSize: localMerch.showSize,
        blurb: localMerch.blurb,
        itemImage: basketImage,
        id:`${sku}-${randomNumber()}-${size}-${colour}`}
    )
    
    if(result.length === 0){
      console.log('add', id, itemName, sku, itemCost, size, colour, 1, basketImage)
      handleAdd(id, itemName, sku, itemCost, size, colour, 1, basketImage);
    } else {
      const currentCount = result[0].itemCount;
      handleEdit(id, currentCount + 1)
    }
    setOpenAddConfirm(true);
  }

  const handleFontSelect = (fontName) => {
    const curr = showTextEdit;
    curr.font = fontName;
    setShowTextEdit(curr);
  }

  const handleEditText = (e) => {
    console.log('text', e.target)
    const curr = showTextEdit;
    curr.text = e.target.value;
    setShowTextEdit(curr);
  }

  const changeCanvasBack = () => {
    if(canvas && (canvasColour !== selectedColour)){
      setCanvasColour(selectedColour);
      //const currentObject = canvas.getActiveObject();
      const merchImages = localMerch.images;
      const newImageObj = merchImages.find(item => item.colour === selectedColour);
      const canvObjects = canvas.getObjects();
      const backImage = canvObjects[0];
      backImage.setSrc(newImageObj.images[0].name)
      loadImage(newImageObj.images[0].name).then(returnImage => {
        console.log(returnImage)
        canvas.renderAll()
      });
      // backImage.setSrc(newImageObj.images[0].name).then(
      //   canvas.renderAll()
      // )
      // canvas.renderAll()
    }
  }

  const handleSelectedColour = ( colourHex ) => {
    console.log('add text', )
    setColourValue(colourHex);
  }

  useEffect(() => {
    console.log('Selected colour', selectedColour)
    changeCanvasBack()
  }, [selectedColour])

  useEffect(() => {
    if(showSizes){
      setShowMask(true);
    } else {
      setShowMask(false);
    }
  }, [showSizes])
  
  return (
    
    <div id="container">
      <Header />
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered size={'md'} scrollBehavior='inside' closeOnOverlayClick={false} closeOnEsc={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Lets start at the start....</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className='modalTitle'>Upload an image to start creating your merchandise</div>
              <div className='modalSelect'>
                <input type='file' onChange={handleFileSelect}/>
                <div className='imageDisplay'>
                  {uploadedImage &&  <img src={preview} style={{'aspect-ratio': 'auto'}} alt='Uploaded' /> }
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <div className='uploadMessage'>
                {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
              </div>
              <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUpload} isDisabled={(uploadComplete)?true:false}>Upload</Button>
              <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={onClose} isDisabled={(uploadedImageURI)?false:true}>
                Continue
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal> */}
        <ConfirmBox text={'Delete Image?'} open={confirmOpen.state} handleConfirm={handleConfirm}/>
            <div className={`topDrawer ${(openUpload)?'topDrawerOpen':''}`}>
              <div className='detailTitle'>Image File Upload</div>
              <div className='uploadButtonHolder'>
                <label htmlFor="fileUpload" className="continueButton">Add Image</label>
                <input id='fileUpload' type='file' onChange={handleFileSelect}/>
              </div>
              {/* <div className='drawerImageDisplay'>
                  {(uploadedImage && preview) &&  <div style={{'aspectRatio': 'auto'}} ><img src={preview} style={{'height' : '150px'}} alt='Uploaded' /></div> }
              </div> */}
              <div className='drawerDisplayOuter'>
                  <div className='drawerImageDisplay'>
                    {!preview && <div className='NoImage'>Select an image to see a preview</div>}
                    {(uploadedImage && preview) &&  <div style={{'aspectRatio': 'auto'}} ><img src={preview} style={{'height' : '150px'}} alt='Uploaded' /></div> }
                  </div>
                  {/* {uploadProgress > 0 && <p>Upload Progress: {uploadProgress.toFixed(2)}%</p>}
                  {error && <p style={{ color: 'red' }}>{error}</p>} */}
                  <div className='uploadStats'>
                    <div className='detailTitle'>Image Details</div>
                    <div>Size: {(imageSize.width)?`${imageSize.width}px x ${imageSize.height}px`:''}</div>
                    <div>Resolution: {(imageInfo)? `${imageInfo}dpi`:''}</div>
                    <div className='imageAssess'>
                      {(imageAssess===true) && <div className='itsGood'>That image looks all good.</div>}
                    </div>
                  </div>
                </div>
                <div className='imageErrorPanel'>
                  {uploadError&&<div className='uploadError'>{uploadError}</div>}
                  {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                  {(uploadComplete)?<div className='uploaded'>Image Uploaded to Album.<br/><span className='uploadSubText'>To upload another image, click the button above.</span></div>:''}
                </div>
              {/* <div className='uploadStats'>
                <div className='detailTitle'>Image Details</div>
                <div>Size: {(imageSize.width)?`${imageSize.width}px x ${imageSize.height}px (${imageSize.realWidth?.toFixed(2)}cm x ${imageSize.realHeight?.toFixed(2)}cm)`:''}</div>
                <div>Resolution: {(imageInfo)? `${imageInfo}dpi`:''}</div>
              </div>
              <div className='uploadMessage'>
              {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
              {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
              </div> */}
              <div className='buttonHolder'>
                <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadToStorage} isDisabled={(isUploading || !preview)?true:false}>Upload to Album</Button>
                <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadClose} isDisabled={(isUploading)?true:false}>Close</Button>
              </div>
            </div>
            {/* URL Upload */}
            <div className={`topDrawer ${(openURLUpload)?'topDrawerOpen':''}`}>
              {uploadError}
              <div className='urlUploadOuter'>
                <div className='detailTitle'>Image URL Uploader</div>
                <input
                  className='urlUpload'
                  type="text"
                  placeholder="Enter image URL"
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                />
                <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3}  onClick={handleURLUpload}>Upload Image</Button>
                <div className='drawerDisplayOuter'>
                  <div className='drawerImageDisplay'>
                    {!preview && <div className='NoImage'>Select an image to see a preview</div>}
                    {(uploadedImage && preview) &&  <div style={{'aspectRatio': 'auto'}} ><img src={preview} style={{'height' : '150px'}} alt='Uploaded' /></div> }
                  </div>
                  {/* {uploadProgress > 0 && <p>Upload Progress: {uploadProgress.toFixed(2)}%</p>}
                  {error && <p style={{ color: 'red' }}>{error}</p>} */}
                  <div className='uploadStats'>
                    <div className='detailTitle'>Image Details</div>
                    <div>Size: {(imageSize.width)?`${imageSize.width}px x ${imageSize.height}px (${imageSize.realWidth?.toFixed(2)}cm x ${imageSize.realHeight?.toFixed(2)}cm)`:''}</div>
                    <div>Resolution: {(imageInfo)? `${imageInfo}dpi`:''}</div>
                  </div>
                </div>
                <div className='uploadMessage'>
                {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
                </div>
                <div className='buttonHolder'>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadToStorage} disabled={(isUploading || !preview)?true:false}>Upload to Album</Button>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadClose} disabled={(isUploading)?true:false}>Close</Button>
                </div>
              </div>
            </div>
            {/* DD Upload */}
            <div className={`topDrawer ${(openDDUpload)?'topDrawerOpen':''}`}>
              {uploadError}
              <div className='urlUploadOuter'>
                <div className='detailTitle'>Image Uploader</div>
                 <div className='drawerDisplayOuter'>
                  {/* <div className='drawerImageDisplay'>
                    {!preview && <div className='NoImage'>Select an image to see a preview</div>}
                    {(uploadedImage && preview) &&  <div style={{'aspectRatio': 'auto'}} ><img src={preview} style={{'height' : '150px'}} alt='Uploaded' /></div> }
                  </div>
                  <div className='uploadStats'>
                    <div className='detailTitle'>Image Details</div>
                    <div>Size: {(imageSize.width)?`${imageSize.width}px x ${imageSize.height}px (${imageSize.realWidth?.toFixed(2)}cm x ${imageSize.realHeight?.toFixed(2)}cm)`:''}</div>
                    <div>Resolution: {(imageInfo)? `${imageInfo}dpi`:''}</div>
                  </div> */}
                  <div className='fileListOuter'>
                    {ddFiles?.map((file, index) => {
                      return (
                        <div className='fileListRow'>
                          <div style={{'aspectRatio': 'auto'}}><img src={file.imageURL} style={{'height' : '80px'}} alt='Uploaded' /></div>
                          <div className='ddDetailsPanel'>
                            <div className='detailTitle'>Image Details</div>
                            <div>Size: {(file?.imageDetails?.width)?`${file?.imageDetails?.width}px x ${file?.imageDetails?.height}px (${file?.imageDetails?.realWidth?.toFixed(2)}cm x ${file?.imageDetails?.realHeight?.toFixed(2)}cm)`:''}</div>
                            <div>Resolution: {(file.imageInfo)? `${file.imageInfo}dpi`:''}</div>
                          </div>
                          <div className='imageCheck'>
                            {file.imageAssess}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  
                </div>
                <div className='uploadMessage'>
                {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
                </div>
                <div className='buttonHolder'>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadToStorage} disabled={(isUploading || !preview)?true:false}>Upload to Album</Button>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadClose} disabled={(isUploading)?true:false}>Close</Button>
                </div>
              </div>
            </div>
      <main>
        <section>
          <div className='createContent'>
            <Drawer />
            <div className='leftPanel'>
              <div className='collectionTitle'></div>
              {(showSizes === false) && <div className='specPanel'>
                <div className='panel'><ImageThumbPanel returnClick={handleAddImage}/></div>
                {/* <div className='panel' style={{height: 'fit-content'}}>
                  <AddImage returnDragDrop={handleDragDrop} />
                </div> */}
                <div className='panel'><AddText returnClick={handleAddText}/></div>
                <div className='buttonPanelBot'>
                  <button className='continueButton' onClick={() => navigate(-1)}>back to garments</button>
                  <button className='continueButton' onClick={() => setShowSizes(true)}>continue</button>
                </div>
              </div>}
              {(showSizes === true) && <div className='specPanel'>
                <div className='panel' style={{height: '90%'}}><SizePanel/></div>
                <div className='buttonPanelBot'>
                  <button className='continueButton' onClick={() => setShowSizes(false)}>review design</button>
                  <button className='continueButton' onClick={() => handleBasketAdd()}>add to basket</button>
                </div>
              </div>}
              
            </div>
            <div className='rightPanel'>
              <div className='collectionTitle'></div>
              <div className='designPanel'>
                <div className='designTitle'></div>
                <div className='design'>
                  {/* <Loading /> */}
                  {isRendering && <div className='renderPanel'>Placeholder. Please press continue to advance....</div> }
                  {/* <div ref={ container } /> */}
                  {/* ******* Start of 2D Gen ********/}
                  <div className="genOuter">
                    <div className="twoDCanvasPanel">
                        <div className="canvas">
                            <canvas ref={canvasRef} id="c" width="450" height="600"></canvas>
                        </div>
                        {(!showSizes) && <div className='bottomPanel'>
                          <OrientationPanel images={localMerch.images[0].images} colours={localMerch.colours} />
                          <div className='textTitle'>To change the garment colour, click the colour and click the garment in the designer above.</div>
                        </div> }
                    </div>
                    {(showSizes === false) && <div className='twoDEditPanel'>
                    <button onClick={() => setShowMask(!showMask)} style={{marginBottom: '10px'}} className={`${(!showMask)? 'buttonOff': ''} continueButton`}>{(!showMask)? 'Show Preview': 'Hide Preview'}</button>
                      <div id="boxEdit">
                        <div className="ui top tertiary inverted attached segment">
                            <p>EDIT</p>
                        </div>
                        <div className="ui bottom attached segment">
                            <p>Select object to edit or Add new object from left menu.</p>
                        </div>
                      </div>
                      <div className={`boxEditText ${(showImageEdit!==null)?'showEditText': ''}`}>
                        <div className="">
                            <p>EDIT : Image</p>
                        </div>
                        
                        <div className="ui bottom attached segment">
                            <div className="addTextForm">
                                <div className='editButtonsWrapper'>
                                  <div className='editImage'>
                                    <button onClick={() => handleCenterH()} style={{marginBottom: '10px'}} className='continueButton'>Center</button>
                                    <button onClick={() => handleCenterV()} style={{marginBottom: '10px'}} className='continueButton'>Middle</button>
                                    <button onClick={() => handleImageDelete()} style={{marginBottom: '10px'}} className='continueButton'>Remove</button>
                                  </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className={`boxEditText ${(showTextEdit!==null)?'showEditText': ''}`}>
                        <div className="">
                            <p>EDIT : Text</p>
                        </div>
                        
                        <div className="ui bottom attached segment">
                            <div className="addTextForm">
                                <label className="form-group">
                                    <input type="text" className="form-control" value={textValue} onChange={(e)=>setTextValue(e.target.value)} />
                                    <span>Text</span>
                                    <span className="border"></span>
                                </label>
                                <FontSelect returnClick={handleFontSelect} location={'edit'} />
                                
                                <label className="form-group" style={{marginTop: '15px'}}>
                                    <span>Select your Colour</span>
                                    <span className="border"></span>
                                </label>
                                <ColourPicker setColour={handleSelectedColour} location={'edit'} />

                                {/* <!--<div className="field">
                                <label>Size</label>
                                <input placeholder="14" value="14" type="text" id="editSize">
                                </div>--> */}
                                

                                <div className='editButtonsWrapper'>
                                  <div className='editUpdate'>
                                    <button className='continueButton' onClick={() => handleTextEdit()}>Update</button> 
                                  </div>
                                  <div className='editDelete'>
                                    <button className='continueButton' onClick={() => handleImageDelete()}>Delete</button>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    </div>}
                    {(showSizes === true) && <div className='twoDEditPanel'></div>}
                  </div>
                  {/* ******** End of 2D Gen ******* */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
           
  );
}

export default Create;
