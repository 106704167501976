import { useState } from "react";

import "./CodeListItem.scss"

const CodeListItem = ( props ) => {
    const { code, item } = props;
    const [isOpen, setIsOpen] = useState(false);

    const renderCodeAttrib = () => {
        console.log('attrib', item.codeAttributes)
        // const retAttribs = Object.keys(item.codeAttributes).map((key, index) => {
        //     return <div key={index}><strong>{key}: </strong>{item.codeAttributes[key]}</div>
        // });
        // return retAttribs;
    }

    return (
        <li key={code} className="codesListitem">
          <div onClick={() => setIsOpen(!isOpen)} className="itemHeader">
            <div className="codeDisplay">{code}</div>
            <div className="codeIcon">{isOpen ? "▲" : "▼"}</div>
          </div>
          {isOpen && (
            <div className="codeListPanel">
              <div><strong>Created on:</strong> {item.createDate}</div>
              <div><strong>Max usage:</strong> {item.maxUsage}</div>
              <div><strong>Usage:</strong> {(!item.usage)?'Not used':item.usage}</div>
              <div><strong>Order Complete:</strong> {(item.orderComplete)? 'True':'False'}</div>
              <div><strong>Code Attributes:</strong> {renderCodeAttrib()}</div>
              <div><strong>Is Locked:</strong> {(item.locked)? 'True':'False'}</div>
            </div>
          )}
        </li>
    )
}

export default CodeListItem