import { Link } from 'react-router-dom';
import './BlurbText.css'

const BlurbText = (props) => {
    const { title = null, text, button } = props;

    const buttonStyle = (button?.colour === '#ff0098')?{'background': '#ff0098', color: '#ffffff'}:{'background': '#ffffff', color: '#ff0098'}
    return (
        <>
            {(title)&& <div className='blurbTextTitle'>{title}</div>}
            <div className='textText' dangerouslySetInnerHTML={{__html:text}}/>
            {(button!==null) &&
                <div className='textButton'>
                    <Link className='continueButton' style={buttonStyle} to={button.action}>{button.text}</Link>
                </div>
            }
        </>
    )
}

export default BlurbText;