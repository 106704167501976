import React, { createContext } from "react";
import { handleFirebaseStorage } from "./firebase";
//import { writeData, readData, updateData } from "./firebase";

// Create a Firebase context
export const FirebaseContext = createContext();

// FirebaseProvider component to pass Firebase functionality
const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider value={{ handleFirebaseStorage }}>
      {children}
    </FirebaseContext.Provider>
  );
};
 
export default FirebaseProvider;