import { useRef, useEffect, useState } from 'react';
import ImageDisplay from '../components/molecule/ImageDisplay/ImageDisplay';
import YaayLogo from '../components/atom/Logo/Logo';
import Subscribe from '../components/molecule/Subscribe/Subscribe';
import Footer from '../components/molecule/Footer/Footer';
import FeatureBlurb from '../components/molecule/FeatureBlurb/FeatureBlurb';
import FeaturesDesign from '../components/molecule/FeaturesDesign/FeaturesDesign'
import FeatureCreate from '../components/molecule/FeaturesCreate/FeaturesCreate';
import FeatureEarn from '../components/molecule/FeaturesEarn/FeaturesEarn';
import './Home.css';

const Home = () => {

    const [isSticky, setIsSticky] = useState(false)

    //const parallax = document.getElementById("parallax");
    const parallax = useRef(null);
    const stickyHeader = useRef()

    // Parallax Effect for DIV 1
    //window.addEventListener("scroll", function () {
    const handleScroll = (e) => {
    //let offset = e.target.scrollTop;
    //parallax.style.backgroundPositionY = offset * 0.7 + "px";
    // DIV 1 background will mo ve slower than other elements on scroll.
    let scrolledPage = Math.round(e.target.scrollTop);
    if(scrolledPage > 530) {
        setIsSticky(true);
      } else {
       setIsSticky(false);
      }
    };

    const createContent = {title: 'Create', textTitle: 'Co-create in your own studio', text:'<p>Create together in real time empower communities to interact in new ways. Token gate access and nurture loyalty through the creative process.</p>', feature:<FeatureCreate/>, button: {text: 'Create', action: '/upload', colour: '#ff0098'}}
    const earnContent = {title: 'Earn', textTitle: null, text:'<p>Enhanced earning potential with token gated pop shops featuring your high quality non-fungible made on demand fashion and lifestyle products</p>', feature:<FeatureEarn/>, button: {text: 'Check out open shops', action: '/', colour: '#ffffff'}}
    const toolsContent = {title: 'Discover our design tools', textTitle: null, text:'<p>Use our design suite to create unique patterns, avatars and motifs. Mint them and test configuration on a range of products.</p>', feature:<FeaturesDesign/>, button: null}

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div  >
        {/* <header ref={stickyHeader} className={`headerSticky ${(isSticky)?'sticky':''}`}></header> */}
        <section onScroll={handleScroll} style={{'width': '100%'}}>
            <div id="parallax" className="parallax-item">
                <ImageDisplay />
                <div className='firstOuter'></div>
                <div className='formOuter'>
                <YaayLogo />
                <Subscribe />
                </div> 
            </div>
            <div className="parallax-item">
                <FeatureBlurb content={createContent} />
            </div>
            <div className="parallax-item">
                <FeatureBlurb content={earnContent} type={'typeRight'} />
            </div>
            <div className="parallax-item">
                <FeatureBlurb content={toolsContent} />
            </div>
        </section>
        </div>
    )
}

export default Home;