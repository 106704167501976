import Header from '../components/molecule/Header/Header';
import PopShop from '../components/organism/PopShop/PopShop';
import YaayLogoSmall from '../components/atom/LogoSmall/LogoSmall';
import FooterNew from '../components/molecule/FooterNew/FooterNew';


import './Checkout.css'

const ShopPage = () => {
    return (
        <div id="container">
            <Header /> 
            <main>
                <section>
                    <div className="cart-page">
                        <PopShop />
                    </div>
                </section>
            </main>
        </div>
    )
}

export default ShopPage;