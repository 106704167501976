export const generateCode = () => {
    // Generate two random uppercase letters
    let letters = '';
    for (let i = 0; i < 2; i++) {
      letters += String.fromCharCode(65 + Math.floor(Math.random() * 26)); // 65 is the char code for 'A'
    }
  
    // Generate six random digits
    let digits = '';
    for (let i = 0; i < 6; i++) {
      digits += Math.floor(Math.random() * 10); // Random digit between 0 and 9
    }
  
    // Combine letters and digits
    return letters + digits;
  }
  
  