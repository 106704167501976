import { useEffect, useState } from 'react';
import { writeNewAccessCode, readAccessCodes } from '../../firebase/firebase';
import { generateCode } from '../../helpers/codeHelpers';
import CodeListItem from './CodeListitem';

import './CodeGenerator.scss';

const CodeGenerator = () => {
    const [refetch, setRefetch] = useState(true)
    const [maxUsage, setMaxUsage] = useState(1);
    const [codeAttributes, setCodeAttributes] = useState({projectID: '', projectURL: ''})
    const [codes, setCodes] = useState(null)

    const handleGenerateCode = async (createDate) => {
        const newCode = generateCode();
        const theDate = Date.now();

        console.log(newCode, theDate)

        await writeNewAccessCode(newCode, theDate, maxUsage, codeAttributes).then(
            setRefetch(!refetch)
        )

    }

    const getCodes = async () => {
        await readAccessCodes('accessCodes/' ).then((ret) => {
            setCodes(ret)
            console.log(ret)
        })
    }

    const renderCodes = () => {
        if(codes === null) return
        const retObjects = Object.keys(codes).map((key, index) => {
            return <CodeListItem key={index} code={key} item={codes[key]}/>
        });
        return <ul className='codesList'>{retObjects}</ul>
    }

    useEffect(() => {
        getCodes()
    }, [refetch])

 return (
    <div className='codeGenOuter'>
        <button className='continueButton' onClick={() => handleGenerateCode()}>Generate Code</button>
        <div>{renderCodes()}</div>
    </div>
 )
    
}

export default CodeGenerator;