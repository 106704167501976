import { 
    Button,
    Card,
    CardBody,
    Image,
    Stack,
    HStack,
    Heading,
    Text,
    Divider,
    CardFooter,
    ButtonGroup,
    IconButton,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    VStack,
    Lorem
} from "@chakra-ui/react";
import { selectContext, operatingContext } from '../../../context/Context';
import { ViewIcon, AddIcon } from '@chakra-ui/icons';
import SizePanelMini from '../SizePanelMini/SizePanelMini'
import ColourPanel from '../ColourPanel/ColourPanel'
import BuyButton from "../../atom/BuyButton/BuyButton";
import './ShopCard.scss';
import { useState, useContext, useEffect } from "react";
import { useShoppingBasket } from '../../../context/Context';
import heartIcon from '../../../assets/svg/heart.svg'
import { useNavigate } from 'react-router-dom';
import { calcItemCost } from "../../../helpers/costHelpers";

const ShopCard = ( props ) => {
    const { details }  = props;
    const [isOpen, setIsOpen] = useState(false);
    const {selectedColour, setSelectedColour, selectedSize} = useContext(selectContext);
    const { state, dispatch } = useShoppingBasket();
    const [ mainImageSrc, setMainImageSrc] = useState(details.ItemImage[0])
    const navigate = useNavigate();
    const {setOpenAddConfirm, currency} = useContext(operatingContext);
    const {currentSelection, setCurrentSelection} = useContext(selectContext)

    const handleQuickView = () => {
        setMainImageSrc(details.ItemImage[0])
        setSelectedColour(details.colours[0])
        setIsOpen(true)
    }

    const handleOnClose = () => {
        setIsOpen(false)
    }

    const handleView = () => {
        setCurrentSelection(
            {itemName: details.ItemName, 
            sku: details.sku, 
            size: selectedSize, 
            colour: selectedColour,
            availColours: details.colours,
            availImages: details.ItemImage,
            itemCost: details.ItemCost,
            sizes: details.sizes,
            showSize: details.showSize,
            blurb: details.blurb,
            id:`${details.sku}-${selectedSize}-${selectedColour}`})
        setMainImageSrc(details.ItemImage[0])
        setSelectedColour(details.colours[0])
        navigate('/viewProduct')
    }

    const handleAdd = (id, itemName, sku, itemCost, itemSize, itemColour, itemCount, itemImage) => {
        dispatch({
          type: 'ADD_ITEM',
          payload: { id: id, name: itemName, sku: sku, itemCost: itemCost, itemColour, itemSize: itemSize, itemCount: itemCount, itemImage: itemImage }
        });
      };
    
      const handleEdit = (id, newCount) => {
        dispatch({ 
          type: 'EDIT_ITEM', 
          payload: { id: id, updates: { itemCount: newCount } } });
      };

    const handleBasketAdd = () => {
        const itemName = details.ItemName;
        const sku = details.sku;
        const size = selectedSize;
        const colour = selectedColour;
        const itemCost = calcItemCost(details.ItemCost, selectedSize, details.sizes);
        const id = `${sku}-${size}-${colour}`
        const itemImage = mainImageSrc
        const result = state.items.filter(obj => { return obj.id === id})
        if(result.length === 0){
          handleAdd(id, itemName, sku, itemCost, size, colour, 1, itemImage);
        } else {
          const currentCount = result[0].itemCount;
          handleEdit(id, currentCount + 1)
        }
        setCurrentSelection(
            {itemName: itemName, 
            sku: details.sku, 
            size: selectedSize, 
            colour: selectedColour,
            availColours: details.colours,
            availImages: details.ItemImage,
            itemCost: details.ItemCost,
            showSize: details.showSize,
            blurb: details.blurb,
            id:`${sku}-${size}-${colour}`})
        setOpenAddConfirm(true);
      }

      useEffect(() => {
        const imgIndex = details.colours.findIndex((element) => element === selectedColour)
        setMainImageSrc(details.ItemImage[imgIndex])

    }, [selectedColour])

    return (
        <Card maxW='sm' className="shopCard">
            <CardBody>
                <Heading style={{height: '80px'}} size='md' color={'#ff0098'}>{details.ItemName}</Heading>
                <Image
                height={'150px'}
                src={details.ItemImage[0]}
                alt={details.ItemName}
                borderRadius='lg'
                />
                <Stack mt='6' spacing='3'>
                    <Text color='#ff0098' fontSize='2xl'>{currency.symbol}{details.ItemCost}</Text>
                </Stack>
            </CardBody>
            <Divider />
            <CardFooter className="cardFooter">
                <ButtonGroup spacing='2'>
                {/* <Button variant='solid' colorScheme='blue'>
                    Buy now
                </Button> */}
                <Tooltip label='Quick View'>
                    <IconButton style={{width: '40px'}} aria-label='Search database' onClick={handleQuickView} icon={<ViewIcon />} variant='outline' colorScheme='pink' color='#ff0098'  />
                    
                </Tooltip>
                <Tooltip label='Add to wishlist'>
                    {/* <IconButton aria-label='Search database' icon={<AddIcon />} variant='outline' colorScheme='pink' color='#ff0098' /> */}
                    <Button  style={{width: '40px'}} className="heartButton" aria-label="Add to Wishlist" variant='outline' colorScheme="pink" color='#ff0098'>
                        <img src={heartIcon} alt="Heart Icon" />
                    </Button>
                </Tooltip>
                <Tooltip label='View Product'>
                    {/* <IconButton aria-label='Search database' icon={<AddIcon />} variant='outline' colorScheme='pink' color='#ff0098' /> */}
                    <button style={{width: '100px', height: '40px'}} className="continueButton" onClick={handleView} aria-label="View Product">View</button>
                </Tooltip>
                
                </ButtonGroup>
            </CardFooter>
            <Modal isOpen={isOpen} size={'xl'} onClose={handleOnClose}>
                <ModalOverlay />
                <ModalContent maxH="72vh" maxW="70vw">
                <ModalCloseButton />
                <ModalBody>
                    <HStack>
                        <Image
                            width={'450px'}
                            src={mainImageSrc}
                            alt={details.ItemName}
                            borderRadius='lg'
                            />
                        <VStack align={'left'}>
                            <div className="ShopCardTitle">{details.ItemName}</div>
                            <Text className="ShopCardText">{details.blurb}</Text>
                            <SizePanelMini disabled={!details.showSize} />
                            <ColourPanel type={'slim'} colours={details.colours} />
                            <Text className="ShopCardPrice" color='#ff0098' fontSize='2xl'>{currency.symbol}{calcItemCost(details.ItemCost, selectedSize, details.sizes)}</Text>
                        </VStack>
                        
                    </HStack>
                </ModalBody>
                <ModalFooter>
                    {/* <Button colorScheme='pink' mr={3} onClick={handleOnClose}>
                    Close
                    </Button>
                    <Button variant='ghost' onClick={handleBuy}>Add to Basket</Button> */}
                    <button className="continueButton" onClick={handleBasketAdd}>add to basket</button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </Card>
    )
}

export default ShopCard;