import { useContext, useState, useEffect } from 'react';
import { operatingContext, selectContext } from '../context/Context';
import YaayLogoSmall from '../components/atom/LogoSmall/LogoSmall';
import axios from "axios";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Button
  } from '@chakra-ui/react'
import ImageAlbum from '../components/molecule/ImageAlbum/ImageAlbum';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getApp, initializeApp } from "firebase/app";
import { Link, useNavigate} from 'react-router-dom';
import { isValidFileExtension, getImageDPI, getPngDPI, getImageDetails } from '../helpers/ImageHelpers';
import MerchPanel from '../components/molecule/MerchPanel/MerchPanel';
import Header from '../components/molecule/Header/Header';
import { readCookie, readLocalStorage } from '../helpers/cookies';


import './Merch.css';

const Merch = () => {
    const {uploadedImage, setUploadedImage} = useContext(selectContext)
    const {selectedMerch} = useContext(selectContext)
    
    const navigate = useNavigate();

    useEffect(() => {
      const cook = readCookie('YAAYSelection')
      console.log('cookie', cook)
      const loc = readLocalStorage('testStore')
      console.log('local', loc)
    }, [])

    return (
    <div id="container">
        {/* <Header /> */}
        <Header />
        <div className='merchOuter'>
          <div className='uploadTitle'>Select your merchandise.</div>
          <div className='uploadSubText'>Select the first garment you would like to create</div>
          <div className='merchPanel'>
            <MerchPanel/>
          </div>
        </div>
        <div className='buttonWrapper'>
          <div className='drawerButtons'>
                <button className='continueButton' onClick={() => navigate(-1)}>Back</button>
                {(!selectedMerch) &&
                    <span className='continueButton continueDisabled'>continue</span>
                }
                {(selectedMerch) &&
                    <Link className='continueButton' style={{'background': '#ff0098'}} to={`/create`}>create</Link>
                }
          </div>
        </div>
      </div>
    )
}

export default Merch;