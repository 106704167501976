import { Wrap, 
    WrapItem, 
    Center, 
    Flex, 
    Accordion, 
    AccordionItem,
    AccordionButton,
    Box,
    AccordionIcon,
    AccordionPanel} from '@chakra-ui/react'
import ShopCard from '../../molecule/ShopCard/ShopCard'
import ShopImages from '../../../assets/shopImages'

const PopShop = () => {

    const renderShopItems = () => {
        const stock = ShopImages.map((item, index) => {
            return (
                <WrapItem key={index}>
                    <ShopCard details={item} />
                </WrapItem>
            )
        })
        return stock;
    }

    return (
        <Flex>
            {/* <Accordion flex={1} width={'80px'}>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box as="span" flex='1' textAlign='left'>
                                Apparel
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box as="span" flex='1' textAlign='left'>
                                Home
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box as="span" flex='1' textAlign='left'>
                                Lifestyle
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box as="span" flex='1' textAlign='left'>
                                Tech
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                </AccordionItem>
                
            </Accordion> */}
            <Wrap padding={'8px'} flex={3}>
                { renderShopItems()}
            </Wrap>
        </Flex>
        
    )
}

export default PopShop;