import shop1 from './images/shop/FAERYSAOPHoodie.png'
import shop8 from './images/shop/FAERYSiPadCover.png'
import shop9 from './images/shop/FAERYSiPhone13Case.png'
import shop18 from './images/shop/FAERYSWaterbottle.png'

import VRC2 from './images/shop/VRCTShirt1Red.png';
import VRC3 from './images/shop/VRCTShirt1Black.png';
import VRC1 from './images/shop/VRCTShirt1White.png';

import VRCRear2 from './images/shop/VRCTShirt2Red.png';
import VRCRear3 from './images/shop/VRCTShirt2Black.png';
import VRCRear1 from './images/shop/VRCTShirt2White.png';

import VRCBottle1 from './images/shop/VRCWaterBottle.png';

const images = [
    { id: 1, 
      sku: 'SKU7364287634', 
      ItemImage: [VRC1, VRC2, VRC3], 
      ItemName: 'Rugby Rebels Regular Fit Cotton T-Shirt', 
      ItemCost: '22.95', 
      colours: ['#ffffff', '#c42b20', '#000000'], 
      sizes: [{size:'small', label: 's', increase: 0}, {size:'medium', label: 'm', increase: 0}, {size:'large', label: 'l', increase: 0}, {size:'x-large', label: 'xl', increase: 0}, {size:'xx-large', label: 'xxl', increase: 0.8}, {size:'xxxl', label: 'xxxl', increase: 1.44}],
      blurb: "This premium short sleeve round neck t-shirt is the perfect addition to any wardrobe. Made with soft and comfortable cotton, it's ideal for everyday use. The unisex timeless cut design makes the t-shirt versatile enough for anyone to wear.",
      showSize: true },
    { id: 2, 
      sku: 'SKU7364287635', 
      ItemImage: [VRCRear1, VRCRear2, VRCRear3], 
      ItemName: 'Rugby Rebels Regular Fit Cotton T-Shirt, Rear Print', 
      ItemCost: '22.95', 
      colours: ['#ffffff', '#c42b20','#000000'], 
      sizes: [{size:'small', label: 's', increase: 0}, {size:'medium', label: 'm', increase: 0}, {size:'large', label: 'l', increase: 0}, {size:'x-large', label: 'xl', increase: 0}, {size:'xx-large', label: 'xxl', increase: 0.8}, {size:'xxxl', label: 'xxxl', increase: 1.44}],
      blurb: "This premium short sleeve round neck t-shirt is the perfect addition to any wardrobe. Made with soft and comfortable cotton, it's ideal for everyday use. The unisex timeless cut design makes the t-shirt versatile enough for anyone to wear.",
      showSize: true },
    { id: 3, 
      sku: 'SKU7364287636', 
      ItemImage: [VRCBottle1], 
      ItemName: 'Rugby Rebels Sports Water Bottle', 
      ItemCost: '44.95', 
      colours: ['#ffffff'], 
      sizes: [{size:'one size', label: 'one size', increase: 0}],
      blurb: "Sport Lid Stainless Steel White Coated. Stay hydrated on the go with this stainless steel water bottle. This bottle is a great alternative to single-use plastic, helping you to live a more sustainable lifestyle! The bottle holds approximately 950ml (32oz) of liquid.",
      showSize: false },

  ];
  export default images;