import React from "react";
import './Toggle.css'

const SliderToggle = ({onWord, OffWord, handleChange}) => {

    const handleClick = (e) => {
        const isChecked = e.target.checked;
        handleChange(isChecked);
    }

    return (
        <div className="button r" id="button-1">
          <input type="checkbox" name="Slider" className="checkboxSlider" onChange={handleClick} />
          <div className={(onWord==='MM')?'knobsSize':'knobs'}></div>
          <div className="layer"></div>
        </div>
    )
}

export default SliderToggle;