import './ColourBoxMini.css'

const ColourBoxMini = (props) => {
    const {colourVal} = props;

    return (
        <div style={{background: colourVal}} className={`colourButtonMini `} ></div>
    )
}

export default ColourBoxMini;