import './ColourPanelMini.css'
import ColourBoxMini from '../../atom/ColourBoxMini/ColourBoxMini';
// import { useContext } from 'react';
// import { selectContext } from '../../../context/Context';

const ColourPanelMini = () => {
    // const {selectedColour, setSelectedColour} = useContext(selectContext);
    const colours = ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'];

    // const handleColourSelect = (colour) => {
    //     setSelectedColour(colour)
    // }

    const renderColours = () => {
        const colourReturn = colours.map((colour, index) => {
            return <ColourBoxMini key={index} colourVal={colour} ></ColourBoxMini>
        })
        return colourReturn;
    }

    return (
        <div className='colourOuterMini'>
            <div className='colourPanelMini'>
                {renderColours()}
            </div>
        </div>
    )
}

export default ColourPanelMini;