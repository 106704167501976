import { useState, useEffect, useContext } from 'react';
import YaayLogoSmall from '../../atom/LogoSmall/LogoSmall';
import CartIcon from '../../atom/CartIcon/CartIcon';
import BasketAddModal from '../../atom/BasketAddModal/BasketAddModal';
import Drawer from '../../atom/Drawer/Drawer';
import { operatingContext } from '../../../context/Context';
import Breadcrumb from '../../atom/BreadCrumb/BreadCrumb';

import './Header.css';

const Header = (props) =>{
  const { showBasket = true } = props;
  const { openAddConfirm, setIsDrawerOpen} = useContext(operatingContext)
  const [scroll, setScroll] = useState(false);

  const openBasket = () => {
    setIsDrawerOpen(true)
  }

    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 10);
      });
    });
  
    return (
      <header>
          <div flex='1' className='headerWrapper'><YaayLogoSmall align={'center'}/></div>
          <div className='rightHeaderPanel'>
            {showBasket && 
              <div className='cartClick' onClick={openBasket}>
                <CartIcon />
              </div>
            }
          </div>
          <Breadcrumb />
          <BasketAddModal isOpen={openAddConfirm} />
          <Drawer />
        </header>
    )
  }

  export default Header;