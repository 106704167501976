import { useContext, useEffect, useState } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import PayPalButton from "../../molecule/PayPal/PayPalButtons";
import { useNavigate } from "react-router-dom";
import { operatingContext } from "../../../context/Context";
import { writeOrderData } from "../../../firebase/firebase";
import './Checkout.scss';

const Checkout = (props) => {
    const { totalPrice, items } = props;
    const [orderAmount, setOrderAmount] = useState(0); // Replace with dynamic data if needed
    const [orderDescription, setOrderDescription] = useState("Purchase of Valley RFC Merchandise");
    //const [currency, setCurrency] = useState("USD");
    const { currency} = useContext(operatingContext)


    const navigate = useNavigate();

  
    const createOrder = (data, actions) => { //This will show the initial state when triggered
      return actions.order.create({
        purchase_units : [
          {
            amount: {
              value: totalPrice,
            },
              description: orderDescription,
          }
        ]
      })
    };
    
    // Callback to handle order success
  const handleSuccess = (data) => {
    
    console.log("Transaction Details:", data.details);
    navigate('/orderConfirm', { state: { orderDetails: JSON.stringify(data.details), billAddress: JSON.stringify(data.details.purchase_units[0].shipping.address), shipAddress: JSON.stringify(data.details.purchase_units[0].shipping.address) }});
    // Use shippingAddress and payerName as needed
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      // Extracting payer's name and address
      //const addressCheck = details.payment_source.paypal.address;
      const payerNameFirst = details.payer.name.given_name;
      const payerNameLast = details.payer.name.surname;
      const payerName = `${payerNameFirst} ${payerNameLast}`
      const shippingAddress = details.purchase_units[0].shipping.address;

      const orderData = {
        "user": details.purchase_units[0].payee.merchant_id,
        "order_id": details.id,
        "email": details.purchase_units[0].payee.email_address,
        "description": details.purchase_units[0].description,
        "shipping": details.purchase_units[0].shipping,
        "payment": details.purchase_units[0].payments,
        "payer": details.payer,
        "items": items,
        "create_date": details.create_time,
        "links": details.links
      }

      writeOrderData(`vrfc/${details.id}/`, orderData);

        handleSuccess({
          payerName,
          shippingAddress,
          details,
        });
    });  }

  const onError = (err) => {
    console.error("PayPal Checkout onError", err);
  }

  useEffect(() => {
    setOrderAmount(totalPrice)
  }, [])

  

    return (
        <div className="checkoutOuter">
            {/* <div>
                <label htmlFor="currency">Select Currency: </label>
                <select
                id="currency"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                >
                <option value="USD">USD</option>
                <option value="HKD">HKD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                </select>
            </div> */}

            {/* <PayPalButton
                amount={orderAmount}
                orderDetails={orderDescription}
                currency={currency.code}
                onSuccess={handleSuccess}
            /> */}
          <div className="blankOut">Purchasing currently disabled</div>
          <PayPalButtons
            createOrder={(data, actions) => createOrder(data, actions)}
            onApprove={(data, actions) => onApprove(data, actions)}
            onError={(err) => {onError(err)}}
            forceReRender={[totalPrice]}
          /> 
        </div>
    )
}

export default Checkout;