import './ColourBox.css'

const ColourBox = (props) => {
    const {colourVal, isSelected, returnClick} = props;
    const handleClick = ()   => {
            returnClick(colourVal);
    }

    return (
        <div style={{background: colourVal}} className={`colourButton ${(isSelected)?'colourSelected':''}`} onClick={handleClick}></div>
    )
}

export default ColourBox;