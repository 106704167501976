import { useContext } from 'react'
import { selectContext } from '../../../context/Context'
import OrientationBox from './OrientationBox'
import TShirtFront from '../../../assets/images/MerchTSRoundFront.png'
import TShirtBack from '../../../assets/images/MerchTSBack.png'
import ColourPanel from '../ColourPanel/ColourPanel'
import './OrientationPanel.css'

const OrientationPanel = ( props ) => {
    const { colours, images } = props;
    const {selectedOri, setSelectedOri} = useContext(selectContext) 
    const orientations = [{text: 'front', image: TShirtFront}, {text: 'rear', image: TShirtBack}]

    const handleClick = (oriValue) => {
        setSelectedOri(oriValue);
    }

    return (
        <div className='placementOuter'>
            <div className='placementPanel'>
                {/* <OrientationBox oriValue={{text: 'front', image: images[0].name}} isSelected={(selectedOri === 'front')?true:false} returnClick={handleClick}></OrientationBox> */}
            </div>
            <div className='centerPanel'>
                <ColourPanel colours={colours} />
            </div>
            <div className='placementPanel'>
                {/* <OrientationBox oriValue={{text: 'back', image: images[1].name}} isSelected={(selectedOri === 'back')?true:false} returnClick={handleClick}></OrientationBox> */}
            </div>
        </div>
    )
}

export default OrientationPanel;