import React from "react";

import YAAYLogo from '../../../assets/images/YAAYLogoLatest.png'

import './LogoSmall.css'

const YaayLogoSmall = (props) => {

    let logoAlign
    switch (props.align) {
        case 'right':
          logoAlign = 'alignRight';
          break;
        case 'left':
            logoAlign = 'alignLeft';
          break;
        default:
            logoAlign = 'alignCenter';
      }
    const logoStyle = `logoOuter ${logoAlign}`
    return (
        <div className={logoStyle}>
            <img src={YAAYLogo} className="logoSmall" alt="YAAY Logo" />
        </div>
    )
}

export default YaayLogoSmall;