import './ConfirmBox.css'

const ConfirmBox = ( props ) => {

    const { text, open, handleConfirm  } = props;
  
    return (
        <>
            <div className={open ? 'confirm show' : 'confirm'}>
                <div className="confirm-content">
                <h4>CONFIRM</h4>
                <div>
                    <h2>{text}</h2>
                    <p>This action is final...</p>
                </div>
                </div>
                <div className="confirm-btns">
                <button className='continueButton' onClick={() => handleConfirm(true)}>YES</button>
                <button className='continueButton' onClick={() => handleConfirm(false)}>NO</button>
                </div>
            </div>
            <div 
                className="overlay" 
                onClick={() => handleConfirm(false)} 
            />
        </>

    )
}

export default ConfirmBox;