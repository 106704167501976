import { useControllableProp } from '@chakra-ui/react';
import './ProductBlurb.scss';

const ProductBlurb = ( props ) => {
    const { blurb } = props;
    return (
        <div className='productBlurb'>{blurb}</div>
    )
}

export default ProductBlurb;