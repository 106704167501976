import React from "react";
import foyBlock from '../../../assets/images/foyBlock.png'
import pfpBlock from '../../../assets/images/pfpBlock.png'
import pifiBlock from '../../../assets/images/pifiBlock.png'
import oozeBlock from '../../../assets/images/oozeBlock.png'
import ivBlock from '../../../assets/images/ivBlock.png'
import imageBlock from '../../../assets/images/imageBlock.png'

import './FeaturesDesign.css'

const TopPanel = () => {
    //const {selectedType, setSelectedType} = React.useContext(selectContext);

    const boxes = [
        {type: 'Your Image', image: imageBlock},
        {type: 'FOY', image: foyBlock},
        {type: 'InstaVinci', image: ivBlock},
        {type: 'Avatar', image: pfpBlock},
        {type: 'PiFi', image: pifiBlock},
        {type: 'Oooze', image: oozeBlock},
    ]

    const handleBoxClick = (index, block) => {
        //setisExpanded(isExpanded => !isExpanded)
        //setSelectedType(block.type)
    }

    const renderBlocks = () => {
        const returnBlocks = boxes.map((block, index) => {
            let blockStyle
            // if((block.type) === selectedType){
            //     blockStyle = 'item itemSelected';
            // } else {
                blockStyle = 'item';
            // }

            return ( 
                <li key={index}>
                    <div className={blockStyle} onClick={() => handleBoxClick(index, block)}>
                        <div className={`textOverlay ${(block.type === 'Your Image')? 'smallText': ''}`}>{block.type}</div>
                        <img className="baseImage" src={block.image} alt="" />
                    </div>
                </li>
            )
        })
        return returnBlocks;
    }

    return (
        <div className="topPanelOuter">
            <ul className='wrapPanel'>
                {renderBlocks()}
            </ul>
        </div>
    )
}

export default TopPanel;