import './ColourPanel.css'
import ColourBox from '../../atom/ColourBox/ColourBox';
import { useContext, useEffect } from 'react';
import { selectContext } from '../../../context/Context';

const ColourPanel = (props) => {
    const { type, colours } = props;
    const {selectedColour, setSelectedColour} = useContext(selectContext);

    const handleColourSelect = (colour) => {
        setSelectedColour(colour)
    }

    const renderColours = () => {
        const colourReturn = colours.map((colour, index) => {
            return <ColourBox key={index} colourVal={colour} isSelected={(selectedColour === colour)?true:false} returnClick={handleColourSelect}></ColourBox>
        })
        return colourReturn;
    }

    return (
        <div className='colourOuter'>
            <div className={(type==='slim')?'colourPanelSlim': 'colourPanel'}>
                {renderColours()}
            </div>
        </div>
    )
}

export default ColourPanel;