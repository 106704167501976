import CodeGenerator from '../../../Tools/CodeGenerator/CodeGenerator';
import './Dashboard.scss';

const Dashboard = () => {

    return (
        <div className='dashOuter'>
            <div>Dashboard</div>
            <div><CodeGenerator /></div>
        </div>
    )
}

export default Dashboard;