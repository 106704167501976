import { useContext } from 'react';
import { operatingContext, selectContext } from '../../../context/Context';
import ConfirmBox from '../../atom/ConfirmBox/ConfirmBox';

const AppWrapper = (props) => {
    const { children } = props;

    const {openUpload, setOpenUpload} = useContext(operatingContext);
    const {openURLUpload, setOpenURLUpload} = useContext(operatingContext);
    const {openDDUpload, setOpenDDUpload} = useContext(operatingContext);
    const {confirmOpen, setConfirmOpen} = useContext(selectContext);

    return (
        <>
            
            {children}
        </>
    )
}

export default AppWrapper;