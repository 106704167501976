import './ReviewStars.scss'

const ReviewStars = ( props ) => {
    const { score } = props;

    const renderStars = ( score ) => {
        const totalFull = parseInt(score);
        let gold = '', white = ''
        for(var i = 0; i < totalFull; i++) {
            gold = gold + '*'
          }
        const loopCount = 5 - totalFull;
        for(var i = 0; i < loopCount; i++) {
            white = white + '*'
        }

        if(!score || score === 0){
            return 'No reviews just yet.'
        } else {
            return <><span>{gold}</span>{white}</>
        }
    }

    return (
        <div className="starRating">
            {renderStars(score)}         
        </div>
    )
}

export default ReviewStars;