import React from "react";
import CreateImage from '../../../assets/images/YAAY_Earn.png'

import './FeaturesEarn.css'

const FeatureEarn = () => {
    

    return (
        <div className="topPanelOuter">
            <ul className='wrapPanel'>
                <img src={CreateImage} alt="Create" className="SectionImage" />
            </ul>
        </div>
    )
}

export default FeatureEarn;