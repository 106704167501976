import { Link } from 'react-router-dom';
import BlurbText from '../../atom/BlurbText/BlurbText';
import './FeatureBlurb.css'

const FeatureBlurb = (props) => {

    const { content, type } = props;

    const renderLeftContent = () => {
        return <BlurbText title={content.textTitle} text={content.text} button={content.button}/>
    }

    const renderRightContent = () => {
        return content.feature
    }

    return (
        <div className='blurbOuter'>
            <div className='blurbTitle'>{content.title}</div>
            <div className='blurbContent'>
                <div className={'blurbText'}>
                    {renderLeftContent()}
                </div>
                <div className={'blurbFeature'}>
                    {renderRightContent()}
                </div>
            </div>
        </div>
    )
}

export default FeatureBlurb;