import { useShoppingBasket } from '../../../context/Context';
import './CartIcon.scss';

const CartIcon = () => {
    const { state } = useShoppingBasket();

    const handleBasketClick = () => {
        console.log('basketClicked')
        console.log(state)
    }

    const getItemCount = () => {
        if (state.items.length === 0) return 0;
        let theCount = 0;
        state.items.map((item) => {
            theCount = theCount + item.itemCount;
        })
        return theCount;
    }

    return (
        <div onClick={() => handleBasketClick()} className="items-container">
            <i className="basketIcon">{getItemCount()}</i>
        </div>
    )
}

export default CartIcon;