import { createContext, useContext, useReducer } from 'react';

export const selectContext = createContext();
export const operatingContext = createContext();

const ShoppingBasketContext = createContext();

const initialState = {
  items: []
};

const basketReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      return { ...state, items: [...state.items, action.payload] };
    case 'DELETE_ITEM':
      return { ...state, items: state.items.filter(item => item.id !== action.payload) };
    case 'EDIT_ITEM':
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.payload.id ? { ...item, ...action.payload.updates } : item
        )
      };
    default:
      return state;
  }
};

export const ShoppingBasketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(basketReducer, initialState);

  return (
    <ShoppingBasketContext.Provider value={{ state, dispatch }}>
      {children}
    </ShoppingBasketContext.Provider>
  );
};

export const useShoppingBasket = () => useContext(ShoppingBasketContext);