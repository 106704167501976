import './ImageDisplay.css'
import backgroundImage from '../../../assets/images/BackgroundLaunch.png'

const ImageDisplay = () => {
    // const [displayImage, setDisplayImage] = useState();

    // useLayoutEffect(()=>{
    //     const num1 = 1;
    //     const num2 = 3;
    //     const ranNum = Math.floor(Math.random() * (num2-num1 + 1) + num1);
    //     const useMobile = (window.innerWidth < 400)?true:false;
    //     if(ranNum === 3){
    //         const useImage = (useMobile)?image3Mob:image3V2;
    //         setDisplayImage(useImage)
    //     } else if(ranNum === 2){
    //         const useImage = (useMobile)?image2Mob:image2V2;
    //         setDisplayImage(useImage)
    //     } else {
    //         const useImage = (useMobile)?image1Mob:image1V2;
    //         setDisplayImage(useImage)
    //     }
    // }, [])

    return (
        <div className="bodyDiv" style={{ backgroundImage: `url(${backgroundImage})` }}>

        </div>
    )
}

export default ImageDisplay;