import React, { useState, useEffect } from 'react';
import logoWhite from '../../../assets/images/YAAYLogoWhite.png';
import { Link, useNavigate } from 'react-router-dom';


import './Subscribe.css';

const Subscribe = props => {

    const [requestCode, setrequestCode] = useState('');
    const [createError, setCreateError] = useState(null);
    const navigate = useNavigate();

    const MAILCHIMP_U = 'c2de03a8b8df6f21168b5ef82';
    const MAILCHIMP_ID = '7083ce04e7'

    const postUrl = `https://lypsync.us21.list-manage.com/subscribe/post?u=${MAILCHIMP_U}&id=${MAILCHIMP_ID}`;

    const handleEmail =  (e) => {
        setCreateError(null);
        setrequestCode(e.currentTarget.value);
    }

    

    // const CustomForm = ({ status, message, onValidated }) => {

    //     const [email, setEmail] = useState('');

    //     useEffect(() => {
    //         if(status === "success") clearFields();
    //     }, [status])
        
    //     const clearFields = () => {
    //     setEmail('');
    //     }

    //     const handleSubmit = (e) => {
    //         e.preventDefault();
    //         email &&
    //         email.indexOf("@") > -1 &&
    //         onValidated({
    //             EMAIL: email,
    //         });
    //     }

    //     const handleEmail =  (e) => {
    //         setEmail(e.currentTarget.value)
    //     }
    
    //     return (
    //         <form className="form" onSubmit={(e) => handleSubmit(e)}>
    //             <div className='mc__outer'>
    //                 {status === "sending" && (
    //                     <div className="mc__alert mc__alert--sending">
    //                         sending...
    //                     </div>
    //                 )}
    //                 {status === "error" && (
    //                     <div 
    //                         className="mc__alert mc__alert--error"
    //                         dangerouslySetInnerHTML={{ __html: message }}
    //                     />
    //                 )}
    //                 {status === "success" && (
    //                     <div
    //                         className="mc__alert mc__alert--success"
    //                         dangerouslySetInnerHTML={{ __html: message }}
    //                     />
    //                 )}
    //             </div>
    //             <div className="fieldContainer">
    //                 <div className='fieldLarge'>
    //                     {/* <InputField
    //                         label=""
    //                         onChangeHandler={setEmail}
    //                         type="email"
    //                         value={email}
    //                         placeholder="grab your pre-launch invite . . ."
    //                         isRequired
    //                     /> */}
    //                     <div className="newmodule">
    //                         <input 
    //                             onChange={(e)=>handleEmail(e)}
    //                             type="email" 
    //                             id="fname" 
    //                             name="fname" 
    //                             className="inputYaay" 
    //                             placeholder="Enter your email for a pre-launch invite"/>
    //                     </div>
    //                 </div>
    //                 <div className='fieldSmall'>
    //                     {/* <InputField
    //                         label="YAAY"
    //                         type="submit"
    //                         formValues={[email]}
    //                     /> */}
    //                     <div className="newbutton">
                            
    //                         <button className='buttonLogo'><img src={logoWhite} alt='button Logo'/></button>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className={'title'}>Where creators become entrepreneurs</div>
    //         </form>
    //     );
    // };

    const handleCodeClick = () => {
        if (requestCode === null || requestCode.length <= 0) {
            setCreateError('Please enter your create code')
        } else if (requestCode.length < 7) {
            setCreateError('Oops.. It looks like you have few digits missing. Please check your code')
        } else if (requestCode.length > 7) {
            setCreateError('Oops.. It looks like you have too many characters. Please check your code')
        } else if(requestCode === 'AD56421') {
            navigate('/upload')
        } else if(requestCode === 'HK55321') {
            navigate('/shop')
        } else {
            setCreateError('Oops.. It looks like that code is right. Please try again')
        }
    }

    return (
        <>
        <div className='titleOuter'>
            <div className={'title'}>How you Create, Collaborate and Earn matters. Do it with YAAY</div>
        </div>
        <div className="formContainer">
            {/* <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status} 
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            /> */}
            <div className='layoutWrapper'>
                <div className='formWrapper'>
                    <div className="fieldContainer">
                            <div className='fieldLarge'>
                                {createError !== null &&
                                    <div className="errorAlert">{createError}</div>
                                }
                                {/* <InputField
                                    label=""
                                    onChangeHandler={setEmail}
                                    type="email"
                                    value={email}
                                    placeholder="grab your pre-launch invite . . ."
                                    isRequired
                                /> */}
                                <div className="newmodule">
                                    <input 
                                        onChange={(e)=>handleEmail(e)}
                                        type="email" 
                                        id="fname" 
                                        name="fname" 
                                        className="inputYaay" 
                                        placeholder="Enter your invite code"/>
                                </div>
                            </div>
                            
                            <div className='fieldSmall'>
                                {/* <InputField
                                    label="YAAY"
                                    type="submit"
                                    formValues={[email]}
                                /> */}
                                {/* <div className="newbutton"> */}
                                <button className='continueButton' onClick={() => handleCodeClick()}>create</button>
                                {/* <Link className="continueButton" style={{ height: '50px'}} to={`upload`}>create</Link> */}
                                    {/* <button className='buttonLogo' ><img src={logoWhite} alt='button Logo'/></button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                <div className='formWrapper'>
                <div className="fieldContainer">
                        <div className='fieldLarge'>
                            {/* <InputField
                                label=""
                                onChangeHandler={setEmail}
                                type="email"
                                value={email}
                                placeholder="grab your pre-launch invite . . ."
                                isRequired
                            /> */}
                            <div className="newmodule">
                                <input 
                                    onChange={(e)=>handleEmail(e)}
                                    type="email" 
                                    id="fname" 
                                    name="fname" 
                                    className="inputYaay" 
                                    placeholder="Enter your email to request an invite code"/>
                            </div>
                        </div>
                        <div className='fieldSmall'>
                            {/* <InputField
                                label="YAAY"
                                type="submit"
                                formValues={[email]}
                            /> */}
                            {/* <div className="newbutton"> */}
                                <button className='continueButton' onClick={() => handleCodeClick}>request</button>
                                {/* <Link className="continueButton" to={`upload`} style={{background: '#4294f7', height: '50px'}}>request</Link> */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Subscribe;