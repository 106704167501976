import React from "react";
import CollabImage from '../../../assets/images/YAAY_Collate.png'

import './FeaturesCreate.css'

const FeatureCreate = () => {
    

    return (
        <div className="topPanelOuter">
            <ul className='wrapPanel'>
                <img src={CollabImage} alt="Create" className="SectionImage" />
            </ul>
        </div>
    )
}

export default FeatureCreate;