import { useState, useEffect } from "react";
import { useShoppingBasket } from '../../../context/Context';

const ConfirmItem = (props) => {
    const { item, index } = props;
    const { state, dispatch } = useShoppingBasket();
    const [currentCount, setCurrentCount]= useState(0);
    const [itemTotal, setItemTotal] = useState(0);

    const handleDelete = () => {
        dispatch({ type: 'DELETE_ITEM', payload: item.id });
      };
    
      const handleEdit = (type) => {
        let current = currentCount;
        if (type === '-'){
            current = current - 1;
        } else {
            current = current + 1
        }
        dispatch({ 
          type: 'EDIT_ITEM', 
          payload: { id: item.id, updates: { itemCount: current } } });
      };

    useEffect(() => {
        setCurrentCount(item.itemCount)
        setItemTotal((item.itemCost*item.itemCount).toFixed(2))
        
    })

    return (
        <tr key={index}>
            <td><img src="https://via.placeholder.com/110" alt="Product Image"/></td>
            <td>{item.name}</td>
            <td>x{item.itemCount}</td>
            <td>{itemTotal}</td>
        </tr>  
    )
    

}

export default ConfirmItem;