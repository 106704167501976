import './SizeBox.css'

const SizeBox = (props) => {
    const {children, isButton, isDisabled, isSelected, returnClick} = props;

    const handleClick = () => {
        if(isButton){
            returnClick(children);
        }
    }

    return (
        <div className={`${(isDisabled)?'disabled': 'enabled'} ${(isButton)?'sizeButton':''} ${(isSelected)?'selected':''}`} onClick={handleClick}>{children}</div>
    )
}

export default SizeBox;