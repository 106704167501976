import { useEffect, useState } from 'react';
import SliderControl from '../SliderControl/SliderControl';
import FontData from '../../../assets/data/fonts.json'
import './FontSelect.scss';

const FontSelect = (props) => {
    const { textVal, returnClick, currentFont = null, location = 'create'} = props;
    const [isActive, setIsActive] = useState(false)
    const [btnText, setBtnText] = useState('Select your Font');
    const [selectedFont, setSelectedFont] = useState('arial');
    const [sliderValue, setSliderValue] = useState(22); 
    const [textValue, setTextValue ] = useState(null);

    const handleItemSelect = (fontName) => {
        setBtnText(fontName);
        setSelectedFont(fontName);
        returnClick(fontName);
        setIsActive(false);
    }
    useEffect(() => {
        setTextValue(textVal)
    },[textVal])

    // useEffect(() =>{
    //     console.log('font', selectedFont)
    // }, [selectedFont])

    const handleSliderChange = (value) => {
        setSliderValue(value);
    };

    useEffect(() => {
        if(currentFont){
            setSelectedFont(currentFont);
            setBtnText(currentFont);
        }
    }, [])

    const renderFonts = () => {
        const returnFonts = FontData.map((item, index) => {
            return (<li className="option" key={index} onClick={() => handleItemSelect(item.name)}>
                {(textValue) &&
                    <div className='option-little'>{item.displayName}</div>
                }
                <div style={{fontFamily: item.name, fontSize: `${sliderValue}px`}}  className="option-text" >{(textValue)?textVal:item.displayName}</div>
            </li>)
        })
        return returnFonts;
    }

    return (
        <div className={`select-menu ${(isActive)?'active': ''}`}>
            <div className="select-btn" onClick={() => setIsActive(true)}>
                <span className="sBtn-text" style={{fontFamily: {selectedFont}}}>{btnText}</span>
                <i className="bx bx-chevron-down"></i>
            </div>
            <div className='optionsWrapper' style={(location==='edit')?{right: 0}:{left: '10px'}}>
                <div className='fontSelectTitle'>Select your Font</div>
                <SliderControl value={sliderValue} min={20} max={80} onSliderChange={handleSliderChange} />
                <ul className="options">
                    {renderFonts()}
                </ul>
            </div>
        </div>
    )
}

export default FontSelect;