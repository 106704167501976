import { 
    Image,
    Stack,
    HStack,
    Heading,
    Text,
    Divider,
    CardFooter,
    ButtonGroup,
    Button,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    VStack,
    Lorem
} from "@chakra-ui/react";
import BasketAddItem from "./BasketAddItem";
import { operatingContext, selectContext } from "../../../context/Context";
import { Link, useNavigate } from "react-router-dom";
import './BasketAddModal.scss';
import { useContext, useEffect } from "react";

const BasketAddModal = (props) => {
    const { isOpen } = props;
    const {setOpenAddConfirm} = useContext(operatingContext);
    const {currentSelection, setCurrentSelection} = useContext(selectContext);

    const navigate = useNavigate();

    const handleOnClose = () => {
        setOpenAddConfirm(false);
        setCurrentSelection(null);
        navigate('/shop');
    }

    const handleCheckout = () => {
        navigate('/checkout');
        setOpenAddConfirm(false);
    }

    return (
        <Modal isOpen={isOpen} size={'xs'} onClose={handleOnClose}>
                <ModalOverlay />
                <ModalContent maxH="300px" maxW="70vw">
                <ModalCloseButton />
                <ModalBody>
                    <VStack align={'left'}>
                        <Text className="ShopCardTitle">What a good choice...</Text>
                        <BasketAddItem key={currentSelection?.id} item={currentSelection} />
                        <Text className='addBasketConfirm'>Successfully added to your basket</Text>
                    </VStack>
                </ModalBody>
                <ModalFooter style={{padding: '0 16px 0'}}>
                    <div className='drawerButtons'>
                    <Button style={{'background': '#ff0098', height: '35px'}} colorScheme='blue' mr={3} onClick={handleCheckout}>Checkout</Button>

                    <Button style={{'background': '#ff0098', height: '35px'}} colorScheme='blue' mr={3} onClick={handleOnClose}>Continue shopping</Button>
                    </div>
                </ModalFooter>
                </ModalContent>
            </Modal>
    )
}

export default BasketAddModal;