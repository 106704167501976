import './SizeBoxMini.css'

const SizeBoxMini = (props) => {
    const {children, isButton, isDisabled, isSelected, returnClick} = props;

    const handleClick = () => {
        if(isButton){
            returnClick(children);
        }
    }

    return (
        <div className={`${(isDisabled)?'disabled': 'enabled'} ${(isButton)?'sizeButtonMini':''} ${(isSelected)?'selected':''}`} onClick={handleClick}>{children}</div>
    )
}

export default SizeBoxMini;