import { Link, useMatches } from 'react-router-dom';

import './BreadCrumb.scss'

const Breadcrumb = () => {

    let matches = useMatches();
    // console.log('test', matches) // Get the current route

    const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => match.handle.crumb(match.data));
    // console.log('...', crumbs)

    // const breadcrumbs = routes?.filter((route) => route.path !== '*' && route.element.props?.breadcrumb);
    // return (
    //     <div>
    //     {breadcrumbs &&
    //         breadcrumbs.map((breadcrumb, index) => (
    //         <span key={index}>
    //             <Link to={breadcrumb.props.to}>{breadcrumb.props.breadcrumb}</Link>
    //             {index < breadcrumbs.length - 1 && ' / '}
    //         </span>
    //         ))}
    //     </div>
    // );
    
  }

  export default Breadcrumb;